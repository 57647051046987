import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { apiGET } from "../../apis/services";
import ShiftDetailsModal from "../../components/shifts/ShiftDetailsModal";
import MorningShift from "../../components/shifts/edit-shift/MorningShift";
import EveningShift from "../../components/shifts/edit-shift/EveningShift";

const getShiftOptions = (userShift) => {
  if (userShift === "morning") {
    return ["Morning"];
  } else if (userShift === "evening") {
    return ["Morning", "Evening"];
  } else {
    return ["Morning", "Evening", "Night"];
  }
};

const ShiftTable = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [shiftFilter, setShiftFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shiftOptions, setShiftOptions] = useState([]);

  const currentDate = format(new Date(), "yyyy-MM-dd");
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const options = getShiftOptions(currentUser?.shift);
    setShiftOptions(options);
    fetchShifts();
  }, [shiftFilter, searchQuery, startDate, endDate]);

  const fetchShifts = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();

      if (shiftFilter) params.append("shiftType", shiftFilter);
      if (searchQuery) params.append("name", searchQuery);
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);

      const response = await apiGET(`api/shifts?${params.toString()}`);

      if (response.success) {
        setData(response.data.data);
      } else {
        console.error("Failed to fetch shifts:", response);
      }
    } catch (error) {
      console.error("Error fetching shifts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (row) => {
    setSelectedRow(row);
    setEditMode(false);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setEditMode(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setEditMode(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleShiftChange = (e) => {
    setShiftFilter(e.target.value);
  };

  const handleDateChange = (e, type) => {
    if (type === "start") setStartDate(e.target.value);
    else setEndDate(e.target.value);
  };

  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <Typography variant="h4" gutterBottom>
          Shift History
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          width: "90%",
          marginTop: "30px",
        }}
      >
        <TextField
          label="Search by Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ flex: 1 }}
        />
        <FormControl style={{ minWidth: 150 }}>
          <InputLabel>Shift</InputLabel>
          <Select
            value={shiftFilter}
            onChange={handleShiftChange}
            label="Shift"
          >
            <MenuItem value="">All</MenuItem>
            {shiftOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => handleDateChange(e, "start")}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => handleDateChange(e, "end")}
        />
      </div>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer
          component={Paper}
          style={{ width: "90%", overflowX: "auto" }}
        >
          <Table style={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell>Serial No</TableCell>
                <TableCell>Shift</TableCell>
                <TableCell>Staff Signature</TableCell>
                <TableCell>Supervisor Signature</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.shiftType}</TableCell>
                  <TableCell>{row.staffSignature}</TableCell>
                  <TableCell>{row.supervisorSignature}</TableCell>
                  <TableCell>
                    {format(new Date(row.createdAt), "yyyy-MM-dd")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleView(row)}
                    >
                      View
                    </Button>
                    {format(new Date(row.createdAt), "yyyy-MM-dd") ===
                      currentDate && currentUser.shift === row.shiftType ? (
                      <IconButton
                        color="secondary"
                        onClick={() => handleEdit(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {editMode ? (
        selectedRow?.shiftType === "morning" ? (
          <MorningShift
            open={open}
            onClose={handleClose}
            initialData={selectedRow}
            setData={setData}
            data={data}
          />
        ) : (
          <EveningShift
            open={open}
            onClose={handleClose}
            initialData={selectedRow}
            setData={setData}
            data={data}
          />
        )
      ) : (
        <ShiftDetailsModal
          open={open}
          onClose={handleClose}
          shift={selectedRow}
        />
      )}
    </>
  );
};

export default ShiftTable;
