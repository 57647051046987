import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppRouter from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Custom primary color
    },
    secondary: {
      main: "#dc004e", // Custom secondary color
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
