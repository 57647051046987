import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CssBaseline,
  Divider,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Work as WorkIcon,
  ExitToApp as LogoutIcon,
  AccountCircle as UserIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const drawerWidthOpen = 240;
const drawerWidthClosed = 60;

const SidebarLayout = ({ children }) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [anchorEl, setAnchorEl] = useState(null); // For controlling dropdown menu
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleNavigation = (path, tabName) => {
    if (path === "/login") localStorage.clear();
    setSelectedTab(tabName); // Update the selected tab state
    navigate(path);
  };

  // Handle dropdown open/close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Logic for logout
    localStorage.clear();
    handleMenuClose();
    navigate("/login");
  };

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      {/* AppBar/Header */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {selectedTab} {/* Display the selected tab name in the header */}
            </Typography>
          </div>

          {/* User Avatar with Dropdown Menu */}
          <div>
            <IconButton onClick={handleMenuOpen} color="inherit">
              <Avatar alt="User" src="/path/to/user/profile.jpg" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              keepMounted
            >
              {/* Display user name option with user icon */}
              <MenuItem>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <Typography variant="inherit">
                  {currentUser.username}
                </Typography>{" "}
                {/* Replace with dynamic user name */}
              </MenuItem>
              <Divider />
              {/* Logout option */}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      {/* Drawer/Sidebar */}
      <Drawer
        variant="permanent"
        open={isDrawerOpen}
        sx={{
          width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
          flexShrink: 0,
          whiteSpace: "nowrap",
          transition: "width 0.3s",
          "& .MuiDrawer-paper": {
            width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => handleNavigation("/dashboard", "Dashboard")}
            selected={selectedTab === "Dashboard"}
          >
            <Tooltip
              title="Dashboard"
              placement="right"
              disableHoverListener={isDrawerOpen}
            >
              <ListItemIcon>
                <HomeIcon
                  color={selectedTab === "Dashboard" ? "primary" : "inherit"}
                />
              </ListItemIcon>
            </Tooltip>
            {isDrawerOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
          <ListItem
            button
            onClick={() =>
              handleNavigation(
                "/shift",
                `${
                  currentUser.shift.charAt(0).toUpperCase() +
                  currentUser.shift.slice(1)
                } Shift`
              )
            }
            selected={selectedTab === `${currentUser.shift} Shift`}
          >
            <Tooltip
              title={`${
                currentUser.shift.charAt(0).toUpperCase() +
                currentUser.shift.slice(1)
              } Shift`}
              placement="right"
              disableHoverListener={isDrawerOpen}
            >
              <ListItemIcon>
                <WorkIcon
                  color={
                    selectedTab ===
                    `${
                      currentUser.shift.charAt(0).toUpperCase() +
                      currentUser.shift.slice(1)
                    } Shift`
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
            </Tooltip>
            {isDrawerOpen && (
              <ListItemText
                primary={`${
                  currentUser.shift.charAt(0).toUpperCase() +
                  currentUser.shift.slice(1)
                } Shift`}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleNavigation("/login", "Logout")}>
            <Tooltip
              title="Logout"
              placement="right"
              disableHoverListener={isDrawerOpen}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
            </Tooltip>
            {isDrawerOpen && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content */}
      <main
        style={{
          flexGrow: 1,
          padding: 0,
          marginLeft: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
        }}
      >
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default SidebarLayout;
