import React, { useEffect, useState } from "react";
import MorningShift from "../../components/shifts/morning";
import EveningShift from "../../components/shifts/evening";
import { apiPOST } from "../../apis/services";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";

const Shift = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const checkSubmissionStatus = async () => {
      try {
        setLoading(true);
        const response = await apiPOST(`api/shifts/check`, {
          user: currentUser?._id,
          date: new Date().toISOString().split("T")[0],
          shiftType: currentUser?.shift,
        });

        if (response.success && response.data.hasSubmitted) {
          setHasSubmitted(true);
          setModalOpen(true); // Show modal if already submitted
        } else {
          setHasSubmitted(false);
        }
      } catch (error) {
        toast.error("Failed to check shift submission status.");
        setHasSubmitted(false);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      checkSubmissionStatus();
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    window.location.href = "/dashboard";
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ marginLeft: "-150px" }}>
      {!hasSubmitted && (
        <>
          {currentUser?.shift === "morning" ? (
            <MorningShift />
          ) : (
            <EveningShift />
          )}
        </>
      )}

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <Typography variant="h6" component="div" gutterBottom>
            Today's shift form is already submitted.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You have already submitted your {currentUser?.shift} shift form for
            today. Please check back tomorrow.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Shift;
