import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { apiPOST } from "../../../apis/services";
import { toast } from "react-toastify";
import { format } from "date-fns";

const EveningShift = ({ open, onClose, initialData, setData, data }) => {
  const [formData, setFormData] = useState({
    report: "",
    levelOfSupervision: "",
    incidents: "",
    restraints: "",
    moodAffect: [],
    participationDetails: "",
    staffSignature: "",
    staffDate: "",
    supervisorSignature: "",
    supervisorDate: "",
  });

  const [loading, setLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (initialData) {
      setFormData({
        report: initialData.report || "",
        levelOfSupervision: initialData.levelOfSupervision || "",
        incidents: initialData.incidents || "",
        restraints: initialData.restraints || "",
        moodAffect: initialData.moodAffect || [],
        participationDetails: initialData.participationDetails || "",
        staffSignature: initialData.staffSignature || "",
        staffDate: initialData.staffDate
          ? format(new Date(initialData.staffDate), "yyyy-MM-dd")
          : "",
        supervisorSignature: initialData.supervisorSignature || "",
        supervisorDate: initialData.supervisorDate
          ? format(new Date(initialData.supervisorDate), "yyyy-MM-dd")
          : "",
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      moodAffect: checked
        ? [...prev.moodAffect, name]
        : prev.moodAffect.filter((mood) => mood !== name),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      user: currentUser?._id,
      shiftType: "evening",
      report: formData.report,
      levelOfSupervision: formData.levelOfSupervision,
      incidents: formData.incidents,
      restraints: formData.restraints,
      moodAffect: formData.moodAffect,
      participationDetails: formData.participationDetails,
      staffSignature: formData.staffSignature,
      staffDate: formData.staffDate,
      supervisorSignature: formData.supervisorSignature,
      supervisorDate: formData.supervisorDate,
    };

    try {
      const response = await apiPOST(`api/shifts/${initialData._id}`, body);

      if (response.success) {
        toast.success("Evening shift updated successfully.");
        const updatedData = response.data.shift;
        let temp = [...data];
        const rowIndex = temp.findIndex((item) => item._id === updatedData._id);

        if (rowIndex !== -1) {
          temp[rowIndex] = updatedData;
          setData(temp);
        }

        onClose();
      } else {
        toast.error("Failed to save evening shift.");
      }
    } catch (error) {
      toast.error("An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Evening Shift
            </Typography>

            <Typography style={{ marginTop: "10px" }}>
              Evening Report: Please make sure that you include any redirections
              that were done with time outs that were given, and melt downs that
              occurred with the child.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                margin="normal"
                fullWidth
                multiline
                rows={4}
                label="Evening Report"
                name="report"
                value={formData.report}
                onChange={handleChange}
                placeholder="Describe redirections and melt downs..."
              />
              <TextField
                margin="normal"
                fullWidth
                label="Level of Supervision"
                name="levelOfSupervision"
                value={formData.levelOfSupervision}
                onChange={handleChange}
                placeholder="Enter level of supervision"
              />
              <TextField
                margin="normal"
                fullWidth
                label="Incidents"
                name="incidents"
                value={formData.incidents}
                onChange={handleChange}
                placeholder="Describe any incidents"
              />
              <TextField
                margin="normal"
                fullWidth
                label="Restraints"
                name="restraints"
                value={formData.restraints}
                onChange={handleChange}
                placeholder="Enter any restraints"
              />

              <FormLabel component="legend" sx={{ mt: 2 }}>
                Mood/Affect:
              </FormLabel>
              <FormGroup row>
                {[
                  "Agitated",
                  "Angry",
                  "Arguing",
                  "Blaming",
                  "Bored",
                  "Cooperative",
                  "Distracted",
                  "Hopeless",
                  "Happy",
                  "Hyperactive",
                  "Impatient",
                  "Irritable",
                  "Negative",
                  "Nervous",
                  "Positive",
                  "Anxious",
                  "Isolated",
                  "Worried",
                  "High Strung",
                  "Suspicious",
                  "Tearful",
                  "Unsettled",
                  "Shared",
                ].map((mood) => (
                  <FormControlLabel
                    key={mood}
                    control={
                      <Checkbox
                        name={mood}
                        checked={formData.moodAffect.includes(mood)}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={mood}
                  />
                ))}
              </FormGroup>

              <Typography style={{ marginTop: "10px" }}>
                Recreational, Normalcy Activities, Participation, and
                Therapeutic Value: Note what input the child had into the
                activities, whether they wanted to participate, whether the
                activities were supervised/ unsupervised, and whether they were
                allowed to participate. If they were not, note the reasons why
                and the therapeutic value of the activity.
              </Typography>

              <TextField
                margin="normal"
                fullWidth
                multiline
                rows={4}
                label="Recreational, Normalcy Activities, Participation, and Therapeutic Value"
                name="participationDetails"
                value={formData.participationDetails}
                onChange={handleChange}
                placeholder="Note the input the child had into the activities, their participation, and therapeutic value..."
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Staff Signature"
                    name="staffSignature"
                    value={formData.staffSignature}
                    onChange={handleChange}
                    placeholder="Enter staff signature"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    type="date"
                    label="Date of Staff Signature"
                    name="staffDate"
                    value={formData.staffDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Supervisor Signature"
                    name="supervisorSignature"
                    value={formData.supervisorSignature}
                    onChange={handleChange}
                    placeholder="Enter supervisor signature"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    type="date"
                    label="Date of Supervisor Signature"
                    name="supervisorDate"
                    value={formData.supervisorDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EveningShift;
