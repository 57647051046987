import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SidebarLayout from "../components/layout/SidebarLayout";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Registeration";
import Dashboard from "../pages/dashboard";
import Shift from "../pages/shift/shift";
import ProtectedRoute from "./protectedRoutes";

const AppRouter = () => {
  // Function to check if user is authenticated
  const isAuthenticated = !!localStorage.getItem("authToken");

  return (
    <Router>
      <Routes>
        {/* Redirect home path based on authentication status */}
        <Route
          path="/"
          element={
            <Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <SidebarLayout>
                <Dashboard />
              </SidebarLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/shift"
          element={
            <ProtectedRoute>
              <SidebarLayout>
                <Shift />
              </SidebarLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
