// services.js
const BASE_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// Template for POST requests
export const apiPOST = async (api, body) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const res = await fetch(`${BASE_URL}${api}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify(body),
    });

    const data = await res.json();
    if (!res.ok) {
      return {
        success: false,
        status: res.status,
        data: { message: data.message || "An error occurred" },
      };
    }

    return {
      success: true,
      status: res.status,
      data,
    };
  } catch (error) {
    console.error("API POST error:", error);
    return {
      success: false,
      status: 500,
      data: { message: "Data fetching failed" },
    };
  }
};

// Template for GET requests
export const apiGET = async (api) => {
  try {
    const authToken = localStorage.getItem("authToken");

    const res = await fetch(`${BASE_URL}${api}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      },
    });

    const data = await res.json();
    if (!res.ok) {
      return {
        success: false,
        status: res.status,
        data: { message: data.message || "An error occurred" },
      };
    }

    return {
      success: true,
      status: res.status,
      data,
    };
  } catch (error) {
    console.error("API GET error:", error);
    return {
      success: false,
      status: 500,
      data: { message: "Data fetching failed" },
    };
  }
};
