import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { apiPOST } from "../../apis/services";
import { toast } from "react-toastify";

const MorningShift = () => {
  const [formData, setFormData] = useState({
    report: "",
    levelOfSupervision: "",
    incidents: "",
    restraints: "",
    moodAffect: [],
    date: "",
    staffSignature: "",
    staffDate: "",
    supervisorSignature: "",
    supervisorDate: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      moodAffect: checked
        ? [...prev.moodAffect, name]
        : prev.moodAffect.filter((mood) => mood !== name),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    const body = {
      user: currentUser?._id,
      shiftType: "morning",
      report: formData.report,
      levelOfSupervision: formData.levelOfSupervision,
      incidents: formData.incidents,
      restraints: formData.restraints,
      moodAffect: formData.moodAffect,
      staffSignature: formData.staffSignature,
      staffDate: formData.staffDate,
      supervisorSignature: formData.supervisorSignature,
      supervisorDate: formData.supervisorDate,
    };

    const response = await apiPOST("api/shifts", body);

    if (response.success) {
      toast.success("Shift created successfully.");
      setFormData({
        report: "",
        levelOfSupervision: "",
        incidents: "",
        restraints: "",
        moodAffect: [],
        date: "",
        staffSignature: "",
        staffDate: "",
        supervisorSignature: "",
        supervisorDate: "",
      });
    } else {
      toast.error(
        response.data.message || "An error occurred while creating the shift."
      );
    }

    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Morning Shift
        </Typography>

        <Typography style={{ marginTop: "10px" }}>
          Morning Report: (use checked boxes to indicate overall mood) Also
          explain and describe child mood when he was awakened this morning.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            fullWidth
            multiline
            rows={4}
            label="Morning Report"
            name="report"
            value={formData.report}
            onChange={handleChange}
            placeholder="Describe child's mood when awakened..."
          />
          <TextField
            margin="normal"
            fullWidth
            label="Level of Supervision"
            name="levelOfSupervision"
            value={formData.levelOfSupervision}
            onChange={handleChange}
            placeholder="Enter level of supervision"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Incidents"
            name="incidents"
            value={formData.incidents}
            onChange={handleChange}
            placeholder="Describe any incidents"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Restraints"
            name="restraints"
            value={formData.restraints}
            onChange={handleChange}
            placeholder="Enter any restraints"
          />

          <FormLabel component="legend" sx={{ mt: 2 }}>
            Mood/Affect:
          </FormLabel>
          <FormGroup row>
            {[
              "Agitated",
              "Angry",
              "Arguing",
              "Blaming",
              "Bored",
              "Cooperative",
              "Distracted",
              "Hopeless",
              "Happy",
              "Hyperactive",
              "Impatient",
              "Irritable",
              "Negative",
              "Nervous",
              "Positive",
              "Anxious",
              "Isolated",
              "Worried",
              "High Strung",
              "Suspicious",
              "Tearful",
              "Unsettled",
              "Shared",
            ].map((mood) => (
              <FormControlLabel
                key={mood}
                control={
                  <Checkbox
                    name={mood}
                    checked={formData.moodAffect.includes(mood)}
                    onChange={handleCheckboxChange}
                  />
                }
                label={mood}
              />
            ))}
          </FormGroup>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Staff Signature"
                name="staffSignature"
                value={formData.staffSignature}
                onChange={handleChange}
                placeholder="Enter staff signature"
              />
              <TextField
                margin="normal"
                fullWidth
                type="date"
                label="Date of Staff Signature"
                name="staffDate"
                value={formData.staffDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Supervisor Signature"
                name="supervisorSignature"
                value={formData.supervisorSignature}
                onChange={handleChange}
                placeholder="Enter supervisor signature"
              />
              <TextField
                margin="normal"
                fullWidth
                type="date"
                label="Date of Supervisor Signature"
                name="supervisorDate"
                value={formData.supervisorDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          {error && <Typography color="error">{error}</Typography>}
          {success && <Typography color="success.main">{success}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MorningShift;
