// ShiftDetailsModal.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { format } from "date-fns";

const ShiftDetailsModal = ({ open, onClose, shift }) => {
  if (!shift) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Shift Details</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">
            <strong>Shift Type:</strong> {shift.shiftType}
          </Typography>
          <Typography variant="h6">
            <strong>Staff Signature:</strong> {shift.staffSignature}
          </Typography>
          <Typography variant="h6">
            <strong>Supervisor Signature:</strong> {shift.supervisorSignature}
          </Typography>
          <Typography variant="h6">
            <strong>Report:</strong> {shift.report}
          </Typography>
          <Typography variant="h6">
            <strong>Level of Supervision:</strong> {shift.levelOfSupervision}
          </Typography>
          <Typography variant="h6">
            <strong>Incidents:</strong> {shift.incidents}
          </Typography>
          <Typography variant="h6">
            <strong>Restraints:</strong> {shift.restraints}
          </Typography>
          {shift.moodAffect && (
            <Typography variant="h6">
              <strong>Mood/Affect:</strong> {shift.moodAffect.join(", ")}
            </Typography>
          )}
          {shift.participationDetails && (
            <Typography variant="h6">
              <strong>Participation Details:</strong>{" "}
              {shift.participationDetails}
            </Typography>
          )}
          <Typography variant="h6">
            <strong>Date:</strong>{" "}
            {format(new Date(shift.staffDate), "yyyy-MM-dd")}
          </Typography>
          <Typography variant="h6">
            <strong>Created At:</strong>{" "}
            {format(new Date(shift.createdAt), "yyyy-MM-dd")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShiftDetailsModal;
